<template>
  <v-tooltip v-bind="$attrs" v-on="$listeners" bottom>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        small
        fab
        icon
        :loading="loading"
        :color="computedButtonColorClass"
        :href="href"
        v-bind="attrs"
        v-on="on"
        @click="$emit('click')"
      >
        <v-icon>
          <slot></slot>
        </v-icon>
      </v-btn>
    </template>

    <span>{{ tootTip }}</span>
  </v-tooltip>
</template>

<script>
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";

export default {
  name: "ActionTooltipButton",
  inheritAttrs: false,
  mixins: [buttonSettingsMixin],
  props: {
    href: {
      type: String,
      default: undefined
    },
    tootTip: {
      type: String,
      default: undefined
    },
    iconColorClass: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedButtonColorClass() {
      return this.iconColorClass ? this.iconColorClass : this.buttonColorClass;
    }
  }
};
</script>
